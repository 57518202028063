import React from 'react'
import Text from '@component/Text/Text'
import IconNoInvoice from '@svg/common/ic_no_invoice.svg'

interface IProps {
    className?: string
    textClassName?: string
    text?: string
}

const LeaderfolioEmptyComponent: React.FC<IProps> = ({className, text, textClassName}) => {
    return (
        <div className={`flex justify-center items-center min-h-[200px] ${className}`}>
            <div className={'flex flex-col items-center space-y-[10px]'}>
                <div className={'bg-brown_tint01 dark:bg-dark_brown_tint01 p-[15px] rounded-full'}>
                    <IconNoInvoice className={'w-[40px] fill-gray02 dark:fill-dark_gray02'} />
                </div>
                <Text className={`text-body1 text-brown_shade dark:text-dark_brown_shade ${textClassName}`}>
                    {text}
                </Text>
            </div>
        </div>
    )
}

export default LeaderfolioEmptyComponent
