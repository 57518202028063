import React, {useImperativeHandle, useRef} from 'react'
import {useTranslation} from 'next-i18next'
import WizButton from '@component/buttons/WizButton'
import Text from '@component/Text/Text'
import WizContentModal from '@component/modal/WizContentModal'
import WizImage from '@component/images/WizImage'
import img_not_registered_api from '@image/img_not_registered_api.png'
import img_not_registered_api_dark from '@image/img_not_registered_api_dark.webp'
import useNavigation from '@hook/useNavigation'
import useDark from '@hook/useDark'

interface IProps {}

const NotRegisteredApiModal = ({}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()
    const {isRenderDark} = useDark()
    const {goApi} = useNavigation()

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
    }))

    return (
        <WizContentModal size={'600'} title={t('modal.connect.exchange.title')} ref={modalRef}>
            <WizImage
                src={img_not_registered_api_dark}
                width={300}
                height={150}
                imageClassName={'mx-auto'}
                containerClassName={'mx-auto mt-[30px] hidden dark:block'}
            />
            <WizImage
                src={img_not_registered_api}
                width={300}
                height={150}
                imageClassName={'mx-auto'}
                containerClassName={'mx-auto mt-[30px] dark:hidden'}
            />

            <Text className={'text-gray02 dark:text-dark_gray02 text-center mt-[10px]'}>
                {t('modal.connect.exchange.desc')}
            </Text>

            <WizButton
                className={'mt-[30px] w-full'}
                text={t('modal.connect.exchange.button.text')}
                buttonType={'primary'}
                onClick={() => {
                    goApi()
                    modalRef?.current?.close()
                }}
            />
        </WizContentModal>
    )
}

export default React.forwardRef(NotRegisteredApiModal)
