import React from 'react'
import ProBadgeIcon from '@svg/pro/ic_header_pro_badge.svg'
import Text from '@component/Text/Text'
import Link from 'next/link'
import {useTranslation} from 'next-i18next'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import {ProIpStatus} from '@type/profile'
import {createLog} from '@util/logs'
import {getLinkUrl, URL_ACCOUNT_PRO} from '@constant/url'

interface IProps {
    className?: string
}

const HeaderProBadge: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()

    const {data: me} = useQueryFetchMe(undefined, false)
    const isNotSetProIP = me?.keys?.some(key => key.pro_ip_status === ProIpStatus.Set)

    if (!me?.is_pro) return null

    return (
        <div className={`flex items-center gap-[3px] mb-[2px] justify-end ${className}`}>
            <ProBadgeIcon className={'w-[16px] h-[16px]'} />
            <Link
                href={getLinkUrl(URL_ACCOUNT_PRO)}
                onClick={() => createLog('event', 'portfolio_click_pro_badge')}
                className={'relative'}>
                <Text className={'bg-gradient_pro text-transparent bg-clip-text text-ti3 underline'}>
                    {isNotSetProIP ? t('portfolio.header.usePro.text') : t('portfolio.header.not.usePro.text')}
                </Text>
                <div className={'h-[1px] w-full absolute bottom-[2px] bg-gradient_pro'} />
            </Link>
        </div>
    )
}

export default React.memo(HeaderProBadge)
